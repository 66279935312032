/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

   "use strict";

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Sticky header
        $(window).scroll(function() {
        if ($(this).scrollTop() > 1){
            $('.nav-bar').addClass("sticky");
          }
          else{
            $('.nav-bar').removeClass("sticky");
          }
        });

		// Animated buger icon that activates page slide
        //open the lateral panel
        $('.menu-button-trigger').on('click', function(event){
            event.preventDefault();
            $('.cd-panel').toggleClass('is-visible');
            $('.menu-button-trigger').toggleClass( "is-open" );
        });
        //clode the lateral panel
        $('.cd-panel').on('click', function(event){
            if( $(event.target).is('.cd-panel') || $(event.target).is('.cd-panel-close') ) {
                $('.cd-panel').removeClass('is-visible');
                $('.menu-button-trigger').toggleClass( "is-open" );
                event.preventDefault();
            }
        });


        // Main navigation slidedown submenu
        $('.cd-panel-content ul.sub-menu').closest('li').addClass('drop');

        // Left Sidebar Main Navigation
        var menu_ul = $('.cd-panel-content .panel-main-menu > li.drop > .sub-menu'),
            menu_a  = $('.cd-panel-content .panel-main-menu > li.drop > .panel-main-menu__arrow');

        menu_ul.hide();
        menu_a.click(function(e) {
            e.preventDefault();
            if(!$(this).hasClass('active')) {
                menu_a.removeClass('active');
                menu_ul.filter(':visible').slideUp('normal');

                //menu_li.addClass('active-submenu');
                $(this).addClass('active').next().stop(true,true).slideDown('normal');
            } else {
                $(this).removeClass('active');
                $(this).next().stop(true,true).slideUp('normal');
            }
        });
        $('.cd-panel-content .current-menu-parent').find('.cd-panel-content .sub-menu').slideToggle('normal');

        //Accordion multi open
        $('.multiaccordion__content').hide();
        $('.multiaccordion__head').click(function () {
            $(this).find("> .ui-icon").toggleClass("ui-icon-nonactive ui-icon-active").end();
            $(this).next('.multiaccordion__content').slideToggle();
            $(this).parent().siblings().children().next().slideUp();
            return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
